import React, { useState, useMemo, useEffect, useCallback } from "react";

import axios from "axios";
import getAPIMap from "../routes/ApiUrls";
import { axiosPrivate } from "../common/axiosPrivate";
import dayjs from "dayjs";

// const apiUrl = config.api.url
const apiUrl = process.env.REACT_APP_IP;

// create context
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(window.localStorage.getItem("user")) || null
  );
  const [session, setSession] = useState(
    JSON.parse(window.localStorage.getItem("session")) || null
  );
  const [userId, setUserId] = useState(window.localStorage.getItem("userId") || null);
  const [scope, setScope] = useState(window.localStorage.getItem("scope") || null);
  const [template, setTemplate] = useState("");
  const [role, setRole] = useState("");
  const [paymentSessionId, setPaymentSessionId] = useState(
    window.localStorage.getItem("psession_id") || ""
  );
  // const [severity, setSeverity] = useState("");
  // const [message, setMessage] = useState("");
  // const [open, setOpen] = React.useState(false);
  useEffect(() => {
    const user = window.localStorage.getItem("user");
    if (user) {
      setUser(JSON.parse(user));
      // let data = JSON.parse(user);
      // let temp = data?.realm_access?.roles[0].split("~")[2];
      // setRole(data?.realm_access?.roles[0].split("~")[1]);
      // setTemplate(temp);
      //setTemplate("Recruiter"); // change the template as per the logged in user
    }
    const session = window.localStorage.getItem("session");
    if (session) {
      setSession(JSON.parse(session));
    }
    const userId = window.localStorage.getItem("userId");
    if (userId) {
      setUserId(userId);
    }

    const paymentSessionId = window.localStorage.getItem("psession_id");
    if (paymentSessionId) {
      setPaymentSessionId(paymentSessionId);
    }
  }, []);

  useEffect(() => {
    const listenStorageChange = () => {
      const user = window.localStorage.getItem("user");
      setUser(JSON.parse(user));

      const session = window.localStorage.getItem("session");
      setSession(JSON.parse(session));

      const userId = window.localStorage.getItem("userId");
      setUserId(userId);

      const scope = window.localStorage.getItem("scope");
      setScope(scope);

      const psessionId = window.localStorage.getItem("psession_id");
      setPaymentSessionId(psessionId);
      if (!session && !user) {
        window.localStorage.setItem("logout", dayjs());
        setUser(null);
      }
    };
    window.addEventListener("storage", listenStorageChange);
  }, []);

  useEffect(() => {
    window.localStorage.setItem("user", JSON.stringify(user));
    window.localStorage.setItem("session", JSON.stringify(session));
    window.localStorage.setItem("userId", user?.sub || userId);
    window.localStorage.setItem("scope", user?.scope || scope);
    window.localStorage.setItem("psession_id", paymentSessionId);
    let temp = user?.realm_access?.roles[0].split("~")[2];
    setRole(user?.realm_access?.roles[0].split("~")[1]);

    setTemplate(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, session, userId, paymentSessionId]);

  const setSessionValue = (newValue) => {
    setSession((val) => newValue);
  };

  const refreshTokens = useCallback(() => {
    return axios
      .post(`${apiUrl}/v1/auth/refresh-tokens`, {})
      .then((response) => {
        // setAccessToken(response.data.token);
        setUser(response.data.user);
        return response;
      })
      .catch((error) => {
        setUser(null);
        // setAccessToken(null);
        return error;
      });
  }, []);

  const value = useMemo(() => {
    const logout = async () => {
      let url = getAPIMap("logout");

      return await axiosPrivate
        .post(url, {})
        .then((response) => {
          setSession(null);
          setUser(null);
          setUserId(null);
          setPaymentSessionId("");
          window.localStorage.clear();
          window.localStorage.setItem("logout", dayjs());
        })
        .catch((err) => {});
    };

    return {
      // severity,
      // setSeverity,
      // message,
      // setMessage,
      // open,
      // setOpen,
      user,
      setUser,
      //login,
      logout,
      template,
      refreshTokens,
      userId,
      setUserId,
      session,
      setSessionValue,
      role,
      setPaymentSessionId,
      paymentSessionId,
      scope,
      setScope,
    };
  }, [
    user,
    refreshTokens,
    userId,
    session,
    template,
    role,
    scope,
    setPaymentSessionId,
    paymentSessionId,
  ]);

  // if (!isLoaded) {
  //   return <ThemedSuspense />;
  // }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
